/* import __COLOCATED_TEMPLATE__ from './google-tag-manager-frame-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { addEventListener } from 'ember-lifeline';
import ENV from 'embercom/config/environment';

export default Component.extend({
  cookies: service(),
  googleTagManagerService: service(),
  iFrameSource: 'https://gtm.intercom-marketing.com',

  iFrame() {
    return this.element.querySelector('iframe');
  },

  didInsertElement() {
    this._super(...arguments);
    this.promises = {};
    this.googleTagManagerService.on('gtm-event', this, '_postMessageToGoogleTagManager');
    addEventListener(this, window, 'message', (event) => {
      if (event.origin.match(/https:\/\/gtm\.intercom-marketing\.com/)) {
        if (this.promises[event.data]) {
          this.promises[event.data]();
        }
      }
    });
    let iFrame = this.iFrame();
    if (iFrame && ENV.environment !== 'test') {
      addEventListener(this, iFrame, 'load', () => this._sendGTMCookiesToIFrame());
    }
  },

  _getHeapCookies() {
    let cookies = this.cookies.read();
    let heapCookies = {};
    Object.keys(cookies)
      .filter((key) => key.startsWith('_hp2_'))
      .forEach((key) => (heapCookies[key] = encodeURIComponent(cookies[key])));
    return heapCookies;
  },

  async _sendGTMCookiesToIFrame() {
    let metadata = Object.assign(this._getHeapCookies(), {
      gtm_cookie_consent: this.cookies.read('gtm_cookie_consent'),
      gtm_id: this.cookies.read('gtm_id'),
      _ga: this.cookies.read('_ga'),
      'mutiny.user.token': this.cookies.read('mutiny.user.token'),
    });
    await this.googleTagManagerService
      .pushEvent({
        event: 'set-gtm-cookies-on-gtm-dot-intercom-marketing-dot-com',
        custom_event_metadata: metadata,
      })
      .catch(console.info);

    await this.googleTagManagerService
      .pushEvent({
        event: 'gtm-js.cookie-consent.consentChanged',
        metadata: {
          origin: window.location.hostname,
        },
      })
      .catch(console.info);
  },

  // the willDestroyElement hook is necessary because this component doesn't get torn
  // down in the route transition and results in the gtm-event being sent twice, which
  // breaks on the second call.
  willDestroyElement() {
    this._super(...arguments);
    this.googleTagManagerService.off('gtm-event', this, '_postMessageToGoogleTagManager');
  },

  _postMessageToGoogleTagManager({ resolve, reject, event }) {
    let messageId = event.messageId;
    if (ENV.environment === 'test') {
      console.info(
        `Refusing to send ${event.event} with messageId=${messageId} to the google tag manager iframe in test.`,
      );
      resolve(messageId);
    } else {
      let iFrame = this.iFrame();
      if (iFrame) {
        this.promises[messageId] = () => resolve(messageId);
        iFrame.contentWindow.postMessage(event, this.iFrameSource);
      } else {
        reject('no iframe available to post message to');
      }
    }
  },
});
