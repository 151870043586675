/* import __COLOCATED_TEMPLATE__ from './global-trial-banner.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  PLAN_DATA,
  NGT_ADDON_PLAN_IDS,
  SS_NGT_CORE_PLAN_IDS,
  WHATSAPP_BASE_ID,
  CORE_GROW_ID,
  CORE_ACCELERATE_ID,
} from 'embercom/lib/billing';
import RouteRegexes from 'embercom/lib/route-regexes';

export default class GlobalTrialBanner extends Component {
  @service appService;
  @service cardlessTrialService;
  @service purchaseAnalyticsService;
  @service router;
  @service intershopService;

  @tracked trialToDisplay = null;
  @tracked dismissedTrials =
    JSON.parse(localStorage.getItem('global_trial_banner_dismissed_plan_trials')) || [];

  get app() {
    return this.appService.app;
  }

  get canAppAccessIntershop() {
    return this.app?.canAccessIntershop;
  }

  get canViewIntershopPricingPage() {
    return this.app?.canAccessIntershop && this.intershopService.canAccessPricing;
  }

  get isOnInboxRoute() {
    return (
      this.router.currentRouteName.match(RouteRegexes.inbox) ||
      this.router.currentRouteName.match(RouteRegexes.inbox2)
    );
  }

  get hideBanner() {
    return this.app?.hideGlobalTrialBanner || this.isOnInboxRoute;
  }

  get displayGlobalTrialBanner() {
    return (
      !this.hideBanner &&
      (this.app?.isSalesforceContracted ||
        (!this.app?.isSalesforceContracted &&
          !this.cardlessTrialService.customer?.everInCardlessTrial)) &&
      this.allPlansOnTrial?.length
    );
  }

  get supportedPlanIds() {
    return this.app?.isSalesforceContracted ? NGT_ADDON_PLAN_IDS : SS_NGT_CORE_PLAN_IDS;
  }

  get allPlansOnTrial() {
    let plansOnTrial = this.app?.allPlansOnPricingModel.filter(
      (plan) =>
        this.supportedPlanIds.includes(plan.id) &&
        ((plan.active && plan.activeTrial && !plan.activeTrialIsGraduating) ||
          (plan.isPostTrial && !plan.billableCustomerPlan)),
    );

    let acceleratePlan = plansOnTrial?.filter(
      (plan) => plan.id === CORE_ACCELERATE_ID,
    )?.firstObject;
    if (acceleratePlan && acceleratePlan.activeTrial) {
      plansOnTrial = plansOnTrial.filter((plan) => plan.id !== CORE_GROW_ID);
    }

    return plansOnTrial
      ?.filter((plan) => !this.dismissedTrials.includes(plan.id))
      ?.sortBy('daysToTrialExpiration')
      ?.reverse();
  }

  get latestTrial() {
    let displayedTrial = this.allPlansOnTrial.find((plan) => plan.id === this.trialToDisplay);

    return displayedTrial ?? this.allPlansOnTrial.firstObject;
  }

  get latestTrialName() {
    return this.latestTrial.nameWithProduct;
  }

  get displayDropdown() {
    return this.allPlansOnTrial.length > 1;
  }

  get groupList() {
    return [{ items: this.groupListItems }];
  }

  get groupListItems() {
    return this.allPlansOnTrial
      .filter((plan) => plan.id !== this.latestTrial.id)
      .map((plan) => {
        return {
          value: plan.id,
          text: plan.nameWithProduct,
          when: `${plan.daysToTrialExpiration} days left`,
          component: 'global-trial-banner-dropdown-item',
        };
      });
  }

  get bannerLink() {
    if (!this.app?.isSalesforceContracted) {
      return null;
    }
    let latestTrialId = this.latestTrial.id;
    let planData = PLAN_DATA[latestTrialId];
    if (planData === undefined) {
      return null;
    }
    if (latestTrialId === WHATSAPP_BASE_ID && this.app?.hasActiveWhatsAppIntegration) {
      return {
        text: planData.trialBannerSecondaryLinkText,
        url: planData.trialBannerSecondaryLinkUrl,
      };
    } else {
      return {
        text: planData.trialBannerLinkText,
        url: planData.trialBannerLinkUrl,
      };
    }
  }

  get trialIsFinished() {
    return this.latestTrial.isPostTrial;
  }

  get displayProductLink() {
    return this.app?.isSalesforceContracted && this.productRoute;
  }

  get productRoute() {
    if (this.latestTrial.id === WHATSAPP_BASE_ID) {
      return {
        route: 'apps.app.appstore.app-package',
        action: 'whatsapp-fqam',
      };
    } else {
      let plan = PLAN_DATA[this.latestTrial.id];
      if (!plan) {
        return null;
      }
      return {
        route: plan.productRoute,
      };
    }
  }

  @action
  transitionToProduct() {
    this.trackTrialAnalyticsEvent('product_link');
    if (this.latestTrial.id === WHATSAPP_BASE_ID) {
      this.router.transitionTo(this.productRoute.route, this.productRoute.action);
    } else {
      this.router.transitionTo(this.productRoute.route);
    }
  }

  @action
  transitionToIntershop() {
    this.trackTrialAnalyticsEvent('buy_now_button');
    this.router.transitionTo(
      `apps.app.intershop.add-ons.add-on.pricing`,
      this.latestTrial.product.slug,
    );
  }

  @action
  trackTrialAnalyticsEvent(object) {
    let event_context = this.app?.isSalesforceContracted
      ? 'scm_non_graduating_trials'
      : 'non_graduating_core_plan_trials';
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      place: 'global_trial_banner',
      context: event_context,
      planId: this.latestTrial.id,
    });
  }

  @action
  selectTrialToDisplay(id) {
    this.trialToDisplay = id;
  }

  @action
  dismissTrial(id) {
    let dismissed = [...this.dismissedTrials, id];
    localStorage.setItem('global_trial_banner_dismissed_plan_trials', JSON.stringify(dismissed));
    this.dismissedTrials = dismissed;
  }
}
